import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import MeetingTemplates from "./MeetingTemplates";
import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";
import { Fragment } from "react";


export const userFields = [
    {
        key: 'firstName',
        title: 'First Name',
        require: true
    },
    {
        key: 'lastName',
        title: 'Last Name',
        require: true
    },
    {
        key: 'fullName',
        title: 'Full Name',
        require: true
    },
    {
        key: 'role',
        title: 'Role'
    },
    {
        key: 'GSPersonID',
        title: 'GS PersonID',
        type: 'number'
    },
    {
        key: 'ContactUniqueID',
        title: 'Contact UniqueID',
        type: 'number'
    },
    {
        key: 'MostRecentNPS',
        title: 'Most Recent NPS',
        type: 'number'
    },
    {
        key: 'MostRecentNPSLabel',
        title: 'Most Recent NPS Label'
    },
    {
        key: 'Referenceable',
        title: 'Referenceable'
    },
    {
        key: 'LanguagePreference',
        title: 'Language Preference'
    },
    {
        key: 'EntitlementLevel',
        title: 'Entitlement Level'
    },
    {
        key: 'image',
        title: 'Image URL'
    }
]

const Content_ = ({className}) => {
    // const [user, setUser] = useState(null);
    // useEffect(()=>{
    //     console.log(auth.currentUser.email)
    //     db.collection('users').doc(auth.currentUser.email).get()
    //         .then(snap => {
    //             console.log(snap.data());
    //             setUser(snap.data());
    //         })
    //         .catch(err=>{
    //             console.log('err', err)
    //         });
    // },[]);


    // if(!user) {
    //     return null;
    // }

    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })

                
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);


    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);
        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount            
        });

        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        // PX
        window.aptrinsic("identify",
            IdentifyUser,
            IdentifyAccount
        );

        // const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // console.log("setting globalContext to version=",vNum);
        // window.aptrinsic('set', 'globalContext', {"version":vNum});
        // console.log("set globalContext to version=",vNum);
    }
    
    return (
        <div className={className}>

            <div className="float-right"><Link id="profile-edit" to="/profile/edit">Edit</Link></div>

            <div className="fullName">{user.fullName ? user.fullName : '[No Name]'}</div>

            <div className="UserFieldTable">
            {userFields.map(field => {
                return (
                    <Fragment key={field.key}>
                    <div className="UserFieldTitle">{field.title}:</div>
                    <div className="UserFieldValue">{user[field.key]}</div>
                    </Fragment>
                )
            })}
            </div>


        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    .float-right {
        float: right;
    }

    .fullName {
        font-weight: bold;
        color: #232333;
        font-size: 24px;
    }
    

    .UserFieldTable {
        display: grid;
        grid-template-columns: 200px 1fr;
        & > div {
            padding-top: 7px;

            font-size: 13px;
            line-height: 20px;

        }
        & > div.UserFieldTitle {
            padding-right: 20px;

            color: #747487;
            display: inline-block;
            min-width: 100px;
    
        }
        & > div.UserFieldValue {

        }
    }
    
`

export default Content

// snippet to load more users
// {
//     const items = [
//     {
//       "ARR": "542000",
//       "stateName": "California",
//       "numberOfEmployees": "9860",
//       "industry": "Technology",
//       "MRR": "45000",
//       "name": "Abbett",
//       "NPSScore": "100",
//       "OriginalContractDate": "6/4/2018",
//       "Referenceable": "TRUE",
//       "regionName": "South Central",
//       "RenewalDate": "12/30/2019",
//       "RevenueBand": "Enterprise",
//       "Segment": "Enterprise",
//       "Stage": "Renewal",
//       "Status": "Active",
//       "HealthScoreLabel": "Yellow",
//       "HealthScore": "67",
//       "id": "JB000029"
//     },
//     {
//       "ARR": "76800",
//       "stateName": "South Carolina",
//       "numberOfEmployees": "1000",
//       "industry": "Manufacturing",
//       "MRR": "6400",
//       "name": "Acme",
//       "NPSScore": "0",
//       "OriginalContractDate": "11/15/2016",
//       "Referenceable": "TRUE",
//       "regionName": "Southwest",
//       "RenewalDate": "11/12/2020",
//       "RevenueBand": "Low Touch",
//       "Segment": "Mid-Market",
//       "Stage": "Implementation",
//       "Status": "Active",
//       "HealthScoreLabel": "Green",
//       "HealthScore": "75",
//       "id": "JB000096"
//     },
//     {
//       "ARR": "48400",
//       "stateName": "Pennsylvania",
//       "numberOfEmployees": "30",
//       "industry": "Technology",
//       "MRR": "4033",
//       "name": "Slack",
//       "NPSScore": "-5.26",
//       "OriginalContractDate": "3/7/2012",
//       "Referenceable": "FALSE",
//       "regionName": "Southwest",
//       "RenewalDate": "4/18/2021",
//       "RevenueBand": "Enterprise",
//       "Segment": "Corporate",
//       "Stage": "Renewal",
//       "Status": "Active",
//       "HealthScoreLabel": "Green",
//       "HealthScore": "75",
//       "id": "JB000078"
//     }
//   ]


//     items.forEach(item => {
//         //db.collection('accounts').add(user);
//         db.collection('accounts').doc(item.id).set(item);
//     })
// }
