import styled, { css } from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

const Button_ = ({className, children, style, to, ...props}) => {
    if(to){
        return (
            <Link className={className} style={style} to={to} {...props}>{children}</Link>
        )
    }
    return (
        <button className={className} style={style} {...props}>{children}</button>
    )
}

const Button = styled(Button_)`

    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    border-radius: 8px;
    padding: 5px 16px;

    background-color: var(--button-bg-color);
    border-color: var(--button-border-color);
    font-size: 16px;

    width: 100%;
    line-height: 28px;
    color: #fff;

    &:not(:first-child) {
        margin-left: 5px;
    }
    &:hover, &:focus {
        /* background-color: #2681f2;
        border-color: #2681f2; */
        background-color: var(--button-bg-color-hover);
        border-color: var(--button-border-color-hover);
        color: #fff;
        text-decoration: none;
    }

    ${p => p.secondary && css`
        background-color: #fff;
        border-color: #babacc;
        color: #333;
        &:hover {
            background-color: #ebebeb;
            color: #333;
            border-color: #babacc;
        }
    `}
    

`;

export default Button