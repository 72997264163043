import styled from "styled-components";
import React from "react";


const Ul = styled.ul`
    color: #fff;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Li = styled.li`
    display: block;
    padding: 0 5px 0 15px;
    &:last-child {
        padding-right: 25px;
    }
    a{
        color: #fff;
    }
`

const HeaderBarOne_ = ({className}) => {
    return (
        <div className={className}>
            <Ul>
                {/* <Li>REQUEST A DEMO</Li> */}
                <Li>
                    <a id="header-request-demo" href="/">REQUEST A DEMO</a>
                </Li>
                <Li>
                    <a id="header-phone" href="/">1.866.792.9223</a>
                </Li>
                <Li>
                    <a id="header-resources" href="/">RESOURCES</a>
                </Li>
                <Li>
                    <a id="header-support" href="/">SUPPORT</a>
                </Li>

            </Ul>
        </div>
    )
}
const HeaderBarOne = styled(HeaderBarOne_)`
    min-height:40px;
    /* background-color: #39384B; */
    background-color:var(--header-bg-color);
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    font-size: 11px;
`
export default HeaderBarOne