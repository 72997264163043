import styled from "styled-components";
import React from "react";

const H1 = styled.h1`
    text-align: center;
    font-size: 24px;
    margin: 0 0 32px;
    padding-top: 48px;
    color: #232333;
    font-weight: 500;
`;

export default H1