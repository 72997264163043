import styled from "styled-components";
import React from "react";

const Caret = styled.span`
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
`;

export default Caret;