import styled, { css } from "styled-components";
import React, { useEffect,useState } from "react";
import { Route, Link } from "react-router-dom";
import Button from "../../../Elements/Button";
import { db, auth } from "../../../../config/firebase";
import moment from 'moment';
import magnifyingglass from '../../../../images/magnifyingglass.png'
import { useForm } from "react-hook-form";

const ActionButton = styled.button`
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    color: #333;
    background-color: #fff;
    border-color: #ccc;

    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;

    border-radius: 8px;

    border-width: 1px;
    padding: 5px 12px;
    border-color: #babacc;

    padding-top: 4px;
    padding-bottom: 4px;

    &:not(:first-child) {
        margin-left: 4px;
    }

    &:hover {
        color: #333;
        background-color: #ebebeb;
    }

    ${p=>p.disabled && css`
        pointer-events: none;
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65;
    `}
    
`

const RecordingsList = styled.div`
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr;

    margin-top: 34px;

    .FirstCol {
        padding-left: 32px;
    }
    .CheckboxCol {
        padding-right: 8px;
    }
    
    .RecordingHeader {

        font-weight: 600;
        font-size: 12px;
        min-height: 34px;
        line-height: 34px;
        
        line-height: 34px;
        border-bottom: 2px solid #eee;
    }
    .RecordingCell {
        line-height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
`

const Recording_ = ({className, topic, recordingDate, meetingId, deleteRecording, id, fileSize, selected, toggleSelection}) => {
    const momentDate = moment(recordingDate.toDate());
    const recordingDateFormatted = momentDate.format('ddd, MMM Do');
    const recordingDateTimeFormatted = momentDate.format('h:mm:ss A');
    const meetingIdFormatted =  meetingId.toString().substring(0, 3) + ' ' + meetingId.toString().substring(3, 7) + ' ' + meetingId.toString().substring(7, 11);
    return (
        <>
            <div className="RecordingCell CheckboxCol FirstCol"><input id="select-recording" type="checkbox" checked={selected} onChange={e=>toggleSelection(id)}/></div>
            <div className="RecordingCell ">{topic}</div>
            <div className="RecordingCell">{meetingIdFormatted}</div>
            <div className="RecordingCell ">{recordingDateFormatted}<br/>{recordingDateTimeFormatted}</div>
            <div className="RecordingCell">{fileSize}</div>
            <div className="RecordingCell">
                <ActionButton id="start-recording">Start</ActionButton>
                <ActionButton id="delete-recording" onClick={e=>deleteRecording(id)}>Delete</ActionButton>
            </div>
        </>
    )
}
const Recording = styled(Recording_)`
`

const CloudRecordings_ = ({className}) => {
    const [recordings, setRecordings] = useState();
    const [filteredRecordings, setFilteredRecordings] = useState([]);
    const [selectedRecordings, setSelectedRecordings] = useState([]);
    const { register, handleSubmit, watch, errors } = useForm();
    const [filterText, setFilterText] = useState('');

    useEffect(()=>{
        db.collection('recordings').where('uid','==',auth.currentUser.uid).get()
            .then(snap => {
                // console.log(snap.size);
                let recordings = [];
                snap.forEach(doc => {
                    console.log('RECORDING', doc.id, doc.data());
                    recordings.push({...doc.data(), id: doc.id});
                });
                setRecordings(recordings);
            })
    }, []);
    useEffect(()=>{
        setFilteredRecordings(recordings ? recordings.filter(rec=> {
            console.log("REC", rec, filterText);
            return rec.topic.toLowerCase().includes( filterText.toLowerCase() )
        }) : []);
    },[recordings, filterText]);

    const deleteRecording = id => {
        db.collection('recordings').doc(id).delete()
            .then(snap => {
                console.log('deleted document', id);
                setRecordings(recordings =>  recordings.filter(m=>m.id!==id))
            })
            .catch(err => {
                console.log('could not delete doc', id, err);
            })
    }
    const toggleSelection = id => {
        if(selectedRecordings.includes(id)){
            setSelectedRecordings(selectedRecordings.filter(v=>v!==id));
        } else {
            setSelectedRecordings([...selectedRecordings, id]);

        }
    }

    const handleSelectAll =() => {
        if(selectedRecordings.length===recordings.length){
            setSelectedRecordings([]);
        } else {
            setSelectedRecordings(recordings.map(v=>v.id));
        }
    }
    
    const deleteSelected = async e => {
        const confirmed = window.confirm("Are you sure you want to delete selected recordings?");
        if(!confirmed) return;
        for(const id of selectedRecordings){
            await db.collection('recordings').doc(id).delete();
            setRecordings(recordings => recordings.filter(v=>v.id!==id));
            setSelectedRecordings(selectedRecordings => selectedRecordings.filter(v=>v!==id));
        }
    }
    const deleteAll = async e => {
        const confirmed = window.confirm("Are you sure you want to delete all recordings?");
        if(!confirmed) return;
        for(const recording of filteredRecordings){
            await db.collection('recordings').doc(recording.id).delete();
            setRecordings(recordings => recordings.filter(v=>v.id!==recording.id));
            setSelectedRecordings(selectedRecordings => selectedRecordings.filter(v=>v!==recording.id));
        }
    }

    const onSearchSubmit = ({query}) => {
        setSelectedRecordings([]);
        setFilterText(query);
    }
    
    return (
        <div className={className}>

            <Button id='create-new-recording' to="/recording/create" style={{width: "auto"}}>Create a New Recording</Button>

            <div className="SearchRow space-between">

                <form onSubmit={handleSubmit(onSearchSubmit)} className="SearchInputContainer">
                    <div className="InputIcon"><img src={magnifyingglass} /></div>
                    <input type="text" className="SearchInput" name="query" ref={register} />
                    <div className="SearchInputActions">
                        <Button id="search-recording">Search</Button>
                    </div>
                </form>

                <div>
                    <ActionButton onClick={deleteSelected} disabled={!selectedRecordings.length}>Delete Selected{
                        selectedRecordings.length ? ` (${selectedRecordings.length})` : ''
                    }</ActionButton>
                    <ActionButton onClick={deleteAll} disabled={!filteredRecordings.length}>Delete All</ActionButton>
                </div>
            </div>
            
            <RecordingsList>
                <div className="RecordingHeader FirstCol CheckboxCol"><input id="select-all-recordings" type="checkbox" checked={!!filteredRecordings && !!filteredRecordings.length && selectedRecordings.length===filteredRecordings.length} onChange={handleSelectAll} /></div>
                <div className="RecordingHeader">Topic</div>
                <div className="RecordingHeader">Recording ID</div>
                <div className="RecordingHeader">Start Time</div>
                <div className="RecordingHeader">File Size</div>
                <div className="RecordingHeader"></div>

                {filteredRecordings && filteredRecordings.map(recording => {
                    return (
                        <Recording key={recording.id}
                            {...recording}
                            deleteRecording={deleteRecording}
                            id={recording.id}
                            toggleSelection={toggleSelection}
                            selected={selectedRecordings.includes(recording.id)}
                            />
                    )
                })}
            </RecordingsList>
            
        </div>
    )
}

const CloudRecordings = styled(CloudRecordings_)`

    .SearchRow {
        margin-top: 20px;
    }

    .SearchInputContainer {
        position: relative;
        display: flex;
    }
    .InputIcon {
        position: absolute;
        top: 8px;
        left: 12px;
        width: 14.5px;
        height: 14.5px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    
    .SearchInput {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

        color: #232333;
        border-radius: 8px;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
        padding-top: 0;
        padding-bottom: 0;
        border-color: #babacc;


        display: inline-block;
        vertical-align: middle;
        min-width: 120px;
        display: inline-block;
        width: auto;
        vertical-align: middle;

        width: 220px;
        padding-left: 36px;
    
    }

    .SearchInputActions ${Button} {
        margin-left: 10px;
        padding-top:  1px;
        padding-bottom:  1px;
        font-size: 14px;
    }

    .space-between {
        display: flex;
        justify-content: space-between;
    }
    
`

export default CloudRecordings