import styled from "styled-components";
import React from "react";


const Footer = styled.div`
    /* background-color: #39384B; */
    background-color:var(--header-bg-color);
    color:var(--header-text-color);
    /* color: #fff; */
    min-height: 200px;
    font-size: 11px;
    padding-bottom: 80px;
    p{
    a {
        /* color: #fff; */
        color: var(--header-text-color);
        font-size: 11px;
    }

   } 
`
const Container = styled.div`
    max-width: 1020px;
    margin: 0 auto;

`;
const Grid = styled.div`
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 2fr; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
`;
const Col = styled.div`
    padding-bottom: 50px;
    
    h5 {
        font-size: 12px;
        font-weight: 600;
        margin: 30px 0 12px 0;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    li {
        font-size: 11px;
        list-style-type: none;
        /* color: #fff; */
        color: var(--header-text-color);
        
        a {
            /* color: #fff; */
            color: var(--header-text-color);
        }
    }


        
   
`;


export default () => (
    <Footer>

        <Container>
            <Grid>
                <Col>
                    <h5>About</h5>
                    <ul>
                        <li>
                            <a id="footer-start-meeting" href="/">Face2Face Blog</a>
                        </li>
                        <li>
                            <a id="footer-customers" href="/">Customers</a>
                        </li>
                        <li>
                            <a id="footer-our-team" href="/">Our Team</a>
                        </li>
                        <li>
                            <a id="footer-why-f2f" href="/">Why Face2Face</a>
                        </li>
                        <li>
                            <a id="footer-features" href="/">Features</a>
                        </li>
                        <li>
                            <a id="footer-careers" href="/">Careers</a>
                        </li>
                        <li>
                            <a id="footer-integrations" href="/">Integrations</a>
                        </li>
                        <li>
                            <a id="footer-partners" href="/">Partners</a>
                        </li>
                        <li>
                            <a id="footer-investors" href="/">Investors</a>
                        </li>
                        <li>
                            <a id="footer-press" href="/">Press</a>
                        </li>
                        <li>
                            <a id="footer-media-kit" href="/">Media Kit</a>
                        </li>
                        <li>
                            <a id="footer-how-to-videos" href="/">How to Videos</a>
                        </li>
                    </ul>
                </Col>
                <Col>
                    <h5>Download</h5>
                    <ul>
                        <li>
                            <a id="footer-meetings-client" href="/">Meetings Client</a>
                        </li>
                        <li>
                            <a id="footer-rooms-client" href="/">Rooms Client</a>
                        </li>
                        <li>
                            <a id="footer-rooms-controller" href="/">Rooms Controller</a>
                        </li>
                        <li>
                            <a id="footer-browser-extension" href="/">Browser Extension</a>
                        </li>
                        <li>
                            <a id="footer-outlook-plugin" href="/">Outlook Plug-in</a>
                        </li>
                        <li>
                            <a id="footer-iphone-ipad-app" href="/">iPhone/iPad App</a>
                        </li>
                        <li>
                            <a id="footer-android-app" href="/">Android App</a>
                        </li>

                    </ul>
                </Col>
                <Col>
                    <h5>Sales</h5>
                    <ul>
                        <li>
                            <a id="footer-contact-sales" href="/">Contact Sales</a>
                        </li>
                        <li>
                            <a id="footer-pricing" href="/">Plans &amp; Pricing</a>
                        </li>
                        <li>
                            <a id="footer-request-demo" href="/">Request a Demo</a>
                        </li>
                        <li>
                            <a id="footer-webinars-and-events" href="/">Webinars and Events</a>
                        </li>

                    </ul>
                </Col>
                <Col>
                    <h5>Support</h5>
                    <ul>
                        <li>
                            <a id="footer-account" href="/">Account</a>
                        </li>
                        <li>
                            <a id="footer-support-center" href="/">Support Center</a>
                        </li>
                        <li>
                            <a id="footer-live-training" href="/">Live Training</a>
                        </li>
                        <li>
                            <a id="footer-feedback" href="/">Feedback</a>
                        </li>
                        <li>
                            <a id="footer-contact-us" href="/">Contact Us</a>
                        </li>
                        <li>
                            <a id="footer-accessibility" href="/">Accessibility</a>
                        </li>
                        <li>
                            <a id="footer-privacy-and-security" href="/">Privacy and Security</a>
                        </li>

                    </ul>
                </Col>
                <Col></Col>
            </Grid>

            <p>Copyright &nbsp; ©2020 &nbsp;&nbsp; Face2Face, Inc. &nbsp;&nbsp; All rights reserved.&nbsp;&nbsp; | &nbsp; &nbsp;<a id="footer-privacy" href="/">Privacy</a> &nbsp; &amp; &nbsp;<a id="footer-legal-policies" href="/">Legal Policies</a> &nbsp;&nbsp; | &nbsp;&nbsp; <a id="footer-about-ads" href="/">About Ads</a></p>

        </Container>
        
    </Footer>

)