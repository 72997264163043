import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import MeetingTemplates from "./MeetingTemplates";
import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";
import { Fragment } from "react";


export const accountFields = [
    {
        key: 'name',
        title: 'Name',
        required: true
    },
    {
        key: 'ARR',
        title: 'ARR',
        type: 'number'
    },
    {
        key: 'stateName',
        title: 'State'
    },
    {
        key: 'numberOfEmployees',
        title: 'Number of Employees',
        type: 'number'
    },
    {
        key: 'industry',
        title: 'Industry'
    },
    {
        key: 'MRR',
        title: 'MRR',
        type: 'number'
    },
    {
        key: 'NPSScore',
        title: 'NPS Score',
        type: 'number'
    },
    {
        key: 'OriginalContractDate',
        title: 'Original Contract Date'
    },
    {
        key: 'Referenceable',
        title: 'Referenceable'
    },
    {
        key: 'regionName',
        title: 'Region Name'
    },
    {
        key: 'RevenueBand',
        title: 'Revenue Band'
    },
    {
        key: 'Segment',
        title: 'Segment'
    },
    {
        key: 'Stage',
        title: 'Stage'
    },
    {
        key: 'Status',
        title: 'Status'
    },    {
        key: 'HealthScoreLabel',
        title: 'Health Score Label'
    },    {
        key: 'HealthScore',
        title: 'Health Score',
        type: 'number'
    },    {
        key: 'GSID',
        title: 'GSID'
    }
]

const Content_ = ({className}) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })

                
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);


    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        // PX
        window.aptrinsic("identify",
            IdentifyUser,
            IdentifyAccount
        );

        // const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // console.log("setting globalContext to version=",vNum);
        // window.aptrinsic('set', 'globalContext', {"version":vNum});
        // console.log("set globalContext to version=",vNum);
    }
    
    return (
        <div className={className}>
                        <div className="float-right"><Link id="account-edit" to="/account/edit">Edit</Link></div>

            <div className="fullName">{account.name ? account.name : '[No Account Name]'}</div>

            <div className="UserFieldTable">
            {accountFields.map(field => {
                return (
                    <Fragment key={field.key}>
                    <div className="UserFieldTitle">{field.title}:</div>
                    <div className="UserFieldValue">{account[field.key]}</div>
                    </Fragment>
                )
            })}
            </div>


        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    .float-right {
        float: right;
    }

    .fullName {
        font-weight: bold;
        color: #232333;
        font-size: 24px;
    }
    

    .UserFieldTable {
        display: grid;
        grid-template-columns: 200px 1fr;
        & > div {
            padding-top: 7px;

            font-size: 13px;
            line-height: 20px;

        }
        & > div.UserFieldTitle {
            padding-right: 20px;

            color: #747487;
            display: inline-block;
            min-width: 100px;
    
        }
        & > div.UserFieldValue {

        }
    }
    
`

export default Content