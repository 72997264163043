import styled from "styled-components";
import React from "react";
import HeaderBarOne from "./HeaderBarOne";
import HeaderBarTwo from "./HeaderBarTwo";


const HeaderContainer = styled.div`
    background-color: #fefdfe;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 700;
    font-size: 12px;
`


export default () => (
    <HeaderContainer>
        {/* <HeaderBarOne/> */}
        <HeaderBarTwo/>
    </HeaderContainer>

)