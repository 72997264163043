import styled from "styled-components";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Route, Link, Redirect } from "react-router-dom";
import steeltoe from 'steeltoe'
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import Breadcrumbs from "../../Elements/Breadcrumbs";
import FormRow from "../../Elements/FormRow";
import Input from "../../Elements/Input";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../Elements/Button";
import { useForm } from "react-hook-form";
import { auth, db } from "../../../config/firebase";
import moment from 'moment';
import { accountFields } from "./Account";


const Alert = styled.div`
    padding: 6px 8px;
    border: 1px solid red;
    border-radius: 6px;
`


const Content_ = ({className}) => {
    // const [startDate, setStartDate] = useState(new Date());
    // const [startDate, setStartDate] = useState(minDate.toDate());
    const { register, handleSubmit, watch, errors } = useForm();
    const [firebaseResult, setFirebaseResult] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [profileData, setProfileData] = useState(null);

    useEffect(()=>{

        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const profile = snap.data();
                setProfileData(profile)

                db.collection('accounts').doc(profile.accountId).get()
                // db.collection('accounts').doc('77840557').get()
                    .then(snap => {
                        const accountData = snap.data();
                        setAccountData(accountData)
                    })
                    .catch(err=>{
                        console.log('Failed getting account data:', err)
                    })
            })
            .catch(err=>{
                console.log('Failed getting profile data:', err)
            })

    },[auth.currentUser.email]);


    // useEffect(()=>{

    //     db.collection('accounts').doc(auth.currentUser.email).get()
    //     // db.collection('accounts').doc('77840557').get()
    //         .then(snap => {
    //             setAccountData(snap.data())
    //         })
    //         .catch(err=>{
    //             console.log('Failed getting account data:', err)
    //         })

    // },[auth.currentUser.email]);
    

    const onSubmit = (data) => {

        console.log('onSubmit', data);
        
        // const randMeetingId = Math.floor(1000000000 + Math.random() * 9000000000);
        
        let sendData = {
            // uid:auth.currentUser.uid,
            ...data
        }

        accountFields.forEach(field => {
            const type = field.type;
            if(field.type === 'number'){
                sendData[field.key] = parseInt(sendData[field.key],10);
                if(sendData[field.key]!==sendData[field.key]){//NaN
                    sendData[field.key] = null;
                }
            }
        })

        console.log('onSubmit sendData:', sendData);
        // console.log('onSubmit accountData:', accountData);

        db.collection('accounts').doc(accountData.id)
        // db.collection('accounts').doc('77840557')
            .set(sendData, {merge: true})
            .then(snap => {
                console.log('Account saved');
                setFirebaseResult({ type:'success' });

            }).catch( err => {
                console.log('Error saving account', err)
                setFirebaseResult({ type:'error', message: err.message });
            })
        
    }

    if(!accountData) {
        return null;
    }

    if(firebaseResult&&firebaseResult.type==='success'){
        return <Redirect to="/account" />
    }


    return (
        <div className={className}>

            <Breadcrumbs path={[
                { name: 'Account', to: "/account"},
                { name: 'Edit Account'}
            ]}/>

            <h3>Edit your Account</h3>

            <form onSubmit={handleSubmit(onSubmit)}>

                {firebaseResult && <Alert {...firebaseResult}>{firebaseResult.message}</Alert>}


                { accountFields.map(accountField => {
                    return (
                        <FormRow key={accountField.key}>
                            <div>{accountField.title}</div>
                            <div>
                            <Input name={accountField.key} 
                                defaultValue={accountData[accountField.key]}
                                placeholder={accountField.title}
                                // inputRef={register}
                                inputRef={register(accountField.required && {
                                    validate: value => value ? true : 'Enter '+accountField.title
                                })}
                                invalid={!!steeltoe(errors).get(accountField.key+'.message')}
                                message={steeltoe(errors).get(accountField.key+'.message')}
                                type={accountField.type || 'text'}
                            /> 
                            </div>
                        </FormRow>
                    )
                })}

                

                <FormRow style={{
                    marginTop: 24,
                    paddingTop: 24,
                    borderTop: '1px solid #eeeff2'
                }}>
                    <div></div>
                    <div>
                        <Button id="account-cancel-save" style={{width:"auto", padding: '6px 27px'}} secondary to="/meeting">Cancel</Button>
                        <Button id="account-save" style={{width:"auto", padding: '6px 27px'}} type="submit">Save</Button>
                        {/* <input style={{width:"auto", padding: '6px 27px'}} type="submit"></input> */}

                    </div>
                </FormRow>
            </form>

            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content