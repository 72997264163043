import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";


const Content_ = ({className}) => {
    return (
        <div className={className}>
            <p className="center">
                You do not have any meeting template.<br/>
                Templates are only available for enterprise level subscriptions.
            </p>
            {/* <p className="center">
                You can choose a Meeting, edit it and save as a template.
            </p> */}
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    margin-top: 100px;
    margin-right: 150px;
    .center {
        text-align: center;
    }
    p {
        margin: 0;
        
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content