import styled, { css } from "styled-components";
import React from "react";
import clsx from "clsx";



const Input_ = ({className, type="text", label="", name="", placeholder="", inputRef, invalid, message, defaultValue}) => {

    
    return (
        <div className={clsx(className, {invalid})}>
            <label>
                <span className="thelabel">{label}</span>
                <input type={type} name={name} placeholder={placeholder} ref={inputRef} defaultValue={defaultValue}/>
                {message && <div>{message}</div>}
            </label>
        </div>
    )
}
const Input = styled(Input_)`
    margin-bottom: 24px;

    .thelabel {
        display: block;
        user-select: none;
        margin-bottom: 4px;
        font-size: 14px;
        color: #747487;
    }
    input {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;


        color: #232333;
        border-radius: 8px;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
        padding-top: 0;
        padding-bottom: 0;
        border-color: #babacc;

        height: 40px;
        line-height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        border-color: #babacc;

        &:focus {


            border-color: #66afe9;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);



            border-color: #0e71eb;
            box-shadow: none;
        }

    }

    &.invalid {
        color: #ff1e5a;
        input {
            border-color: #ff1e5a;
        }
    }
`;

export default Input