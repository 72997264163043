import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";



const Breadcrumbs_ = ({className, children, style, path}) => {
    return (
        <div className={className} style={style}>{
            path.map((item,i,arr) => {
                if(i+1===arr.length) {
                    return (
                        <span key={i}>{item.name}</span>
                    )
                }
                return (
                    <span key={i}>
                        <Link to={item.to}>{item.name}</Link>
                        <span className="chevron">&gt;</span>
                    </span>
                )
            })
        }</div>
    )
}

const Breadcrumbs = styled(Breadcrumbs_)`
    .chevron {
        padding: 0 7px;
        color: #ccc;
    }
`;

export default Breadcrumbs