import { Route, Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../Auth/Auth";

function PrivateRoute({ children, ...rest }) {
    const user = useContext(AuthContext);
    // console.log('PRIVATE ROUTE', user)
    return (
      <Route
        {...rest}
        render={({ location }) => {
            // console.log("USER:", user);
            // return null;
            if(user===0) return null;
            return user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: location }
                }}
              />
            )
        }
        }
      />
    );
  }

export default PrivateRoute;