import styled from "styled-components";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";


const LiWithSubmenu_ = ({className, children, title}) => {
    const [open, setOpen] = useState(true);
    return (
        <li className={clsx(className, { open })}>
            <span className={clsx("DropdownTitleContainer", { open })}>
                <span className="DropdownIconContainer">
                    <span className="DropdownIcon">&gt;</span>
                </span>
                <span className="DropdownTitle" onClick={e=>setOpen(v=>!v)}>{title}</span>
            </span>
            {open ? children : null}
        </li>
    )
};
const LiWithSubmenu = styled(LiWithSubmenu_)`

`;

const SideMenu_ = ({className}) => {
    return (
        <div className={className}>
            {/* <h2>PERSONAL</h2> */}
            <ul>
                <li><NavLink to="/profile">Profile</NavLink></li>
                <li><NavLink to="/meeting">Meetings</NavLink></li>
                <li><NavLink to="/whatis">Webinars</NavLink></li>
                {/* <li><NavLink to="/pac">Personal Audio Conference</NavLink></li> */}
                <li><NavLink to="/recording">Recordings</NavLink></li>
                <li><NavLink to="/account">Account Profile</NavLink></li>
                {/* <li><NavLink to="/theme">Admin Theme</NavLink></li> */}
                {/* <li><NavLink to="/meeting/join">Join a Meeting</NavLink></li> */}
                {/* <li><NavLink to="/report">Reports</NavLink></li> */}
            </ul>

            <h2>ADMIN</h2>
            <ul>

                <LiWithSubmenu title="Company Theme">
                    <ul>
                        <li><NavLink to="/admin-call-settings">Default Call Settings</NavLink></li>
                        <li><NavLink to="/admin-virtual-background">Virtual Background</NavLink></li>
                        {/* <li><NavLink to="/admin-colors">Colors</NavLink></li> */}
                    </ul>
                </LiWithSubmenu>
            </ul>

            {/* <h2>ADMIN</h2>
            <ul>

                <LiWithSubmenu title="User Management">
                    <ul>
                        <li><NavLink to="/users">Users</NavLink></li>
                        <li><NavLink to="/groupmanagement">Group Management</NavLink></li>
                        <li><NavLink to="/rolemanagement">Role Management</NavLink></li>
                    </ul>
                </LiWithSubmenu>
            </ul> */}
        </div>
    )
}

const SideMenu = styled(SideMenu_)`
    background-color: #f7f6fa;
    min-height: 100vh;
    /* min-height: 70vh; */ /* removed footer */
    width: 300px;
    min-width: 300px;
    padding: 32px 24px;

    h2 {
        margin: 40px 0 15px 0;
        font-size:12px;
        color: #232333;
        font-weight: 500;
        line-height: 1.1;
        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            display: block;
            padding: 0;
            margin: 0 0 4px 0;
            list-style: none;
            user-select: none;

            
            .DropdownTitleContainer {
                display: flex;
                .DropdownIconContainer {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    top: 6px;
                }
                .DropdownIcon {
                    color: #747487;
                    transition: transform 0.4s;
                }
                &.open .DropdownIcon {
                    color: var(--sidebar-button-color);
                    transform: rotate(90deg);
                }

                .DropdownTitle {
                    margin-bottom: 0;
                    height: 16px;
                    padding: 11px 0;
                    box-sizing: content-box;
                    display: block;
                    cursor: pointer;
                    font-weight: 600;
                    &.focus {
                        color: var(--sidebar-button-color);
                    }
                }
                &:hover .DropdownTitle, &.open .DropdownTitle {
                    color: var(--sidebar-button-color);
    
                }
            }


            a {
                font-weight: 600;
                display: block;
                padding: 9px 16px 9px 22px;
                border-radius: 8px;
                color: #232333;
                text-decoration: none;
                &.active {
                    background-color: var(--sidebar-button-color);
                    color: var(--sidebar-text-color);
                }
            }
        }
    }
    
`

export default SideMenu;