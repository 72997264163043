import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";

import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";


const Content_ = ({className}) => {

    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })

                
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);

    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("visitor Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        // PX
        window.aptrinsic("identify",
            IdentifyUser,
            IdentifyAccount
        );
        
        // const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // console.log("setting globalContext to version=",vNum);
        // window.aptrinsic('set', 'globalContext', {"version":vNum});
        // console.log("set globalContext to version=",vNum);
    }

    return (
        <div className={className}>
            <p>
                What is Webinar?
            </p>
            <ul>
                <li>
                    Face2Face Video Webinars allow you to easily conduct large online events with video, audio and screen sharing for up to 100 video participants and 10,000 attendees
                </li>
                <li>
                    The features in Webinar are tailored for managing large audiences with branding, registration, host controls, polls, question & answer, raise hand, chat, recording and reporting
                </li>
                <li>
                    Monetize your webinars with our Paypal integration (via Zapier)
                </li>
            </ul>
            <p>
                How is Webinar enabled?
            </p>
            <ul>
                <li>
                    Webinar can be added to any paid plans such as Pro, Business or Edu
                </li>
                <li>
                    Go to your Webinar Add-On Plan, or contact your sales representative
                </li>
            </ul>
            <p>
                How do I start?
            </p>
            <ul>
                <li>
                    Click <Link id="webinars-learn-more-link" to="/webinars-more">here</Link> to contact a member from our sales team.
                    {/* On the User Management page, admin can enable Webinars for certain users with Edit User */}
                </li>
            </ul>

            <div className="right">
                <Link id="webinars-learn-more" to="/webinars-more">
                    Learn More
                </Link>
            </div>
            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content