import styled from "styled-components";
import React from "react";



const Alert_ = ({className, children, style, path}) => {
    return (
        <div className={className} style={style}>{children}</div>
    )
}

const Alert = styled(Alert_)`
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;

        color: #b22424;
        background-color: #fff2f2;
        border-color: transparent;
    
`;

export default Alert