import styled from "styled-components";
import React from "react";
import { Route, Link, Redirect } from "react-router-dom";
import steeltoe from 'steeltoe'
import Breadcrumbs from "../../../Elements/Breadcrumbs";
import FormRow from "../../../Elements/FormRow";
import Input from "../../../Elements/Input";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Button from "../../../Elements/Button";
import { useForm } from "react-hook-form";
import { auth, db } from "../../../../config/firebase";
import moment from 'moment';


const Alert = styled.div`
    padding: 6px 8px;
    border: 1px solid red;
    border-radius: 6px;
`


const Content_ = ({className}) => {
    const minDate =  moment().second() ||  moment().millisecond() ?  moment().add(1, 'hour').startOf('hour') : null;
    console.log("minDate",minDate)

    // const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(minDate.toDate());
    const { register, handleSubmit, watch, errors } = useForm();
    const [firebaseResult, setFirebaseResult] = useState(null);
    

    const onSubmit = (data) => {
        const { topic } = data;
        console.log('onSubmit', data);

        const randMeetingId = Math.floor(1000000000 + Math.random() * 9000000000);
        const randFileSize = Math.floor(100 + Math.random() * 900);
        
        const sendData = {
            uid:auth.currentUser.uid,
            topic,
            recordingDate: startDate,
            meetingId: randMeetingId,
            fileSize: randFileSize + ' mb'
        }


        console.log('sendData', sendData);

        db.collection('recordings').add(sendData)
            .then(snap => {
                console.log('recording created');
                setFirebaseResult({ type:'success' });

            }).catch( err => {
                console.log('Error creating recording', err)
                setFirebaseResult({ type:'error', message: err.message });
            })
        
    }

    if(firebaseResult&&firebaseResult.type==='success'){
        return <Redirect to="/recording" />
    }




    return (
        <div className={className}>

            <Breadcrumbs path={[
                { name: 'My Recordings', to: "/recording"},
                { name: 'Create Recording'}
            ]}/>

            <h3>Create Recording</h3>

            <form onSubmit={handleSubmit(onSubmit)}>

                {firebaseResult && <Alert {...firebaseResult}>{firebaseResult.message}</Alert>}

                

                <FormRow>
                    <div>Topic</div>
                    <div><Input name="topic" placeholder="My Recording" 
                        // inputRef={register}
                        inputRef={register({
                            validate: value => value ? true : 'Enter Topic'
                        })}
                        invalid={!!steeltoe(errors).get('topic.message')}
                        message={steeltoe(errors).get('topic.message')}
                    /> 
                    </div>

                    
                </FormRow>
                <FormRow>
                    <div>When</div>
                    <div>
                        <ReactDatePicker selected={startDate} onChange={date => setStartDate(date)} showTimeSelect 
                            // minDate={new Date()}  
                            minDate={minDate}  
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                    </div>
                </FormRow>

                <FormRow style={{
                    marginTop: 24,
                    paddingTop: 24,
                    borderTop: '1px solid #eeeff2'
                }}>
                    <div></div>
                    <div>
                        <Button id="schedule-meeting-cancel" style={{width:"auto", padding: '6px 27px'}} secondary to="/meeting">Cancel</Button>
                        <Button id="schedule-meeting-save" style={{width:"auto", padding: '6px 27px'}} type="submit">Save</Button>
                        {/* <input style={{width:"auto", padding: '6px 27px'}} type="submit"></input> */}

                    </div>
                </FormRow>
            </form>

            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content