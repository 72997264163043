import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";
import Input from "./Input";



const FormRow_ = ({className, children, style, path}) => {
    return (
        <div className={className} style={style}>
            {children}
        </div>
    )
}

const FormRow = styled(FormRow_)`
    display: flex;
    &>* {
        flex-grow: 1;

    }
    &>*:first-child {
        width: 180px;
        flex-grow: 0;

        font-weight: bold;
        color: #747487;
        padding: 7px 15px 0;
    }

    ${Input} {
        max-width: 400px;
    }
`;

export default FormRow