import styled from "styled-components";
import React from "react";
import SideMenu from "../SideMenu/SideMenu";
import MainContent from "../MainContent/MainContent";

const Container = styled.div`
    width: 100vw;
    display: flex;
`



export default () => (
    <Container>
        <SideMenu/>
        <MainContent/>
    </Container>
)