import styled from "styled-components";
import React from "react";
import { NavLink, useRouteMatch, Link } from "react-router-dom";
import clsx from "clsx";

const Tabs_ = ({className, children}) => {
    return (
        <ul className={className}>
            {children}
        </ul>
    )
}
const Tabs = styled(Tabs_)`
    padding: 0;
    margin: 0;
    border-bottom: 2px solid #f7f7fa;
    margin-bottom: 24px;
    display: flex;
`;



const Li = ({children, className}) => <li className={className}>{children}</li>;


const Tab_ = ({className, to, children, exact, ...props}) => {
    // let match = useRouteMatch(to);

    
    // const active = !!match && match.path=== to;

    // console.log('match:', match, '-- for', to, '---', match&&match.path, active);

    return (

        <NavLink exact to={to} className={className} component={Li} {...props}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </NavLink>

    )
}
const Tab = styled(Tab_)`
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    display: block;
    margin-bottom: -2px;
    margin-right: 40px;

    &>a{

        position: relative;
        display: block;
        padding: 10px 15px;

            margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;

        border-bottom: 2px none;
        color: #747487;
        outline: medium none;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        padding: 0;
        padding-bottom: 16px;
        margin-right: 0;
        text-decoration: none;

        &:hover {
            background-color: transparent;
            border-color: transparent;
            /* color: #0e71eb; ORIG BLUE */
            color: var(--sidebar-button-color);
        }
    }
    &.active > a, &.active > a:hover, &.active > a:focus {


        cursor: default;
        background-color: #fff;
        
        
        /* color: #0e71eb; ORIG BLUE*/
        color: var(--sidebar-button-color);
        /* border-bottom: 2px solid #0e71eb; // ORIG BLUE*/
        border-bottom: 2px solid var(--sidebar-button-color);

    }
`;




export { Tabs, Tab };