import styled from "styled-components";
import React from "react";
import { Route, Link, Redirect } from "react-router-dom";
import steeltoe from 'steeltoe'
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import Breadcrumbs from "../../Elements/Breadcrumbs";
import FormRow from "../../Elements/FormRow";
import Input from "../../Elements/Input";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Button from "../../Elements/Button";
import { useForm } from "react-hook-form";
import { auth, db } from "../../../config/firebase";
import moment from 'moment';
import { useEffect } from "react";


const Alert = styled.div`
    padding: 6px 8px;
    border: 1px solid red;
    border-radius: 6px;
`


const Content_ = ({className}) => {
    const minDate =  moment().second() ||  moment().millisecond() ?  moment().add(1, 'hour').startOf('hour') : null;

    // const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(minDate.toDate());
    const { register, handleSubmit, watch, errors } = useForm();
    const [firebaseResult, setFirebaseResult] = useState(null);

    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })

                
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);

    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        // PX
        window.aptrinsic("identify",
            IdentifyUser,
            IdentifyAccount
        );

        // const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // console.log("setting globalContext to version=",vNum);
        // window.aptrinsic('set', 'globalContext', {"version":vNum});
        // console.log("set globalContext to version=",vNum);
    }
    

    const onSubmit = (data) => {
        const { topic } = data;
        console.log('onSubmit', data);


        window.aptrinsic("track","Admin-Call-Settings",{"message":data.welcome,"url":data.topic});

        // const randMeetingId = Math.floor(1000000000 + Math.random() * 9000000000);
        
        // const sendData = {
        //     uid:auth.currentUser.uid,
        //     topic,
        //     meetingDate: startDate,
        //     meetingId: randMeetingId
        // }


        // console.log('sendData', sendData);

        // db.collection('meetings').add(sendData)
        //     .then(snap => {
        //         console.log('Meeting created');
        //         setFirebaseResult({ type:'success' });

        //     }).catch( err => {
        //         console.log('Error creating meeting', err)
        //         setFirebaseResult({ type:'error', message: err.message });
        //     })
        
    }

    // if(firebaseResult&&firebaseResult.type==='success'){
    //     return <Redirect to="/meeting" />
    // }




    return (
        <div className={className}>

            <Breadcrumbs path={[
                // { name: 'Admin', to: "/meeting"},
                { name: 'Admin'},
                { name: 'Default Call Settings'}
            ]}/>

            <h3>Call Settings</h3>

            <form onSubmit={handleSubmit(onSubmit)}>

                {firebaseResult && <Alert {...firebaseResult}>{firebaseResult.message}</Alert>}

                

                <FormRow>
                    <div>Welcome Message</div>
                    <div><Input name="welcome" placeholder="Welcome Message" 
                        // inputRef={register}
                        inputRef={register({
                            validate: value => value ? true : 'Welcome Message'
                        })}
                        invalid={!!steeltoe(errors).get('welcome.message')}
                        message={steeltoe(errors).get('welcome.message')}
                    /> 
                    </div>

                    
                </FormRow>
                <FormRow>
                    <div>Support URL</div>
                    <div><Input name="topic" placeholder="Support URL" 
                        // inputRef={register}
                        inputRef={register({
                            validate: value => value ? true : 'Enter URL'
                        })}
                        invalid={!!steeltoe(errors).get('topic.message')}
                        message={steeltoe(errors).get('topic.message')}
                    /> 
                    </div>

                    
                </FormRow>

                {/* <FormRow>
                    <div>Other Default</div>
                    <div>
                        <ReactDatePicker selected={startDate} onChange={date => setStartDate(date)} showTimeSelect 
                            // minDate={new Date()}  
                            minDate={minDate}  
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                    </div>
                </FormRow> */}

                <FormRow style={{
                    marginTop: 24,
                    paddingTop: 24,
                    borderTop: '1px solid #eeeff2'
                }}>
                    <div></div>
                    <div>
                        <Button id="schedule-meeting-cancel" style={{width:"auto", padding: '6px 27px'}} secondary to="/meeting">Cancel</Button>
                        <Button id="schedule-meeting-save" style={{width:"auto", padding: '6px 27px'}} type="submit">Save</Button>
                        {/* <input style={{width:"auto", padding: '6px 27px'}} type="submit"></input> */}

                    </div>
                </FormRow>
            </form>

            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content