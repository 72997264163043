import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";
import { Fragment } from "react";
import Button from "../../Elements/Button";


const Content_ = ({className}) => {
    const [user, setUser] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                console.log(snap.data());
                setUser(snap.data());
            })
            .catch(err=>{
                console.log('err', err)
            });
    },[]);


    if(!user) {
        return null;
    }

    const meetingId = user.personalMeetingId;
    const meetingIdFormatted =  meetingId.toString().substring(0, 3) + ' ' + meetingId.toString().substring(3, 7) + ' ' + meetingId.toString().substring(7, 11);


    return (
        <div className={className}>

            {/* <div className="float-right"><Link to="/profile/edit">Edit</Link></div> */}

            {/* <div className="fullName">{user.fullName ? user.fullName : '[No Name]'}</div> */}

            <div className="UserFieldTable">
                <Fragment key="Topic">
                    <div className="UserFieldTitle">Topic:</div>
                    <div className="UserFieldValue">My Meeting Room</div>
                </Fragment>
                <Fragment key="MeetingId">
                    <div className="UserFieldTitle">Meeting ID:</div>
                    <div className="UserFieldValue">{meetingIdFormatted}</div>
                </Fragment>
            
            </div>

            <div>
                <br/>
                <br/>
                <Button id='start-meeting' style={{width: "auto"}}>Start Meeting</Button>
            </div>




        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    .float-right {
        float: right;
    }

    .fullName {
        font-weight: bold;
        color: #232333;
        font-size: 24px;
    }
    

    .UserFieldTable {
        display: grid;
        grid-template-columns: 200px 1fr;
        & > div {
            padding-top: 7px;

            font-size: 13px;
            line-height: 20px;

        }
        & > div.UserFieldTitle {
            padding-right: 20px;

            color: #747487;
            display: inline-block;
            min-width: 100px;
    
        }
        & > div.UserFieldValue {

        }
    }
    
`

export default Content