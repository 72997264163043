import styled from "styled-components";
import React, { useEffect,useState } from "react";
import { Route, Link } from "react-router-dom";
import Button from "../../../Elements/Button";
import { db, auth } from "../../../../config/firebase";
import moment from 'moment';

const ActionButton = styled.button`
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    color: #333;
    background-color: #fff;
    border-color: #ccc;

    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;

    border-radius: 8px;

    border-width: 1px;
    padding: 5px 12px;
    border-color: #babacc;

    padding-top: 4px;
    padding-bottom: 4px;

    &:not(:first-child) {
        margin-left: 4px;
    }

    &:hover {
        color: #333;
        background-color: #ebebeb;
    }
`

const MeetingsList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    margin-top: 34px;

    .FirstCol {
        padding-left: 32px;
    }
    
    .MeetingHeader {

        font-weight: 600;
        font-size: 12px;
        min-height: 34px;
        line-height: 34px;
        
        line-height: 34px;
        border-bottom: 2px solid #eee;
    }
    .MeetingCell {
        line-height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
`

const Meeting_ = ({className, topic, meetingDate,meetingId, deleteMeeting, id}) => {
    const momentDate = moment(meetingDate.toDate());
    const meetingDateFormatted = momentDate.format('ddd, MMM Do');
    const meetingDateTimeFormatted = momentDate.format('h:mm:ss A');
    const meetingIdFormatted =  meetingId.toString().substring(0, 3) + ' ' + meetingId.toString().substring(3, 7) + ' ' + meetingId.toString().substring(7, 11);
    return (
        <>
            <div className="MeetingCell FirstCol">{meetingDateFormatted}<br/>{meetingDateTimeFormatted}</div>
            <div className="MeetingCell">{topic}</div>
            {/* <div className="MeetingCell">{id}</div> */}
            <div className="MeetingCell">{meetingIdFormatted}</div>
            <div className="MeetingCell">
                <ActionButton>Start</ActionButton>
                <ActionButton onClick={e=>deleteMeeting(id)}>Delete</ActionButton>
            </div>
        </>
    )
}
const Meeting = styled(Meeting_)`
`

const PreviousMeetings_ = ({className}) => {
    const [meetings, setMeetings] = useState();
    useEffect(()=>{
        db.collection('meetings').where('uid','==',auth.currentUser.uid).where('meetingDate','<',new Date()).get()
            .then(snap => {
                // console.log(snap.size);
                let meetings = [];
                snap.forEach(doc => {
                    meetings.push({...doc.data(), id: doc.id});
                });
                setMeetings(meetings);
            })
    }, []);

    const deleteMeeting = id => {
        db.collection('meetings').doc(id).delete()
            .then(snap => {
                console.log('deleted document', id);
                setMeetings(meetings =>  meetings.filter(m=>m.id!==id))
            })
            .catch(err => {
                console.log('could not delete doc', id, err);
            })
    }
    
    return (
        <div className={className}>

            <Button id="schedule-new-meeting" to="/meeting/schedule" style={{width: "auto"}}>Schedule a New Meeting</Button>
            
            <MeetingsList>

                <div className="MeetingHeader FirstCol">Start Time</div>
                <div className="MeetingHeader">Topic</div>
                <div className="MeetingHeader">Meeting ID</div>
                <div className="MeetingHeader"></div>

                {meetings && meetings.map(meeting => {
                    return (
                        <Meeting key={meeting.id} {...meeting} deleteMeeting={deleteMeeting} id={meeting.id}/>
                    )
                })}
            </MeetingsList>
            
        </div>
    )
}

const PreviousMeetings = styled(PreviousMeetings_)`

    
`

export default PreviousMeetings