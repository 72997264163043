import styled from "styled-components";
import React from "react";
import SideMenu from "../SideMenu/SideMenu";
import MainContent from "../MainContent/MainContent";
import AuthContent from "../../Auth/AuthContent/AuthContent";

const Wrapper = styled.div`
    background-color: #fff;
    padding-bottom: 20px;
`;

const Container = styled.div`
    width: 420px;
    max-width: 420px;
    margin: 0 auto;
    min-height: 70vh;
`



export default () => (
    <Wrapper>
        <Container>
            <AuthContent/>
        </Container>
    </Wrapper>
)