import styled from "styled-components";
import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminDefaultCallSettings from './Content/AdminDefaultCallSettings'
import AdminTheme from './Content/AdminTheme'
import Webinars from './Content/Webinars'
import Meetings from './Content/Meetings'
import Recordings from './Content/Recordings'
import ScheduleMeeting from './Content/ScheduleMeeting'
import JoinMeeting from './Content/JoinMeeting'
import Profile from './Content/Profile'
import ProfileEdit from './Content/ProfileEdit'
import Account from './Content/Account'
import AccountEdit from './Content/AccountEdit'
import PrivateRoute from "../../Routes/PrivateRoute";


const MainContent_ = ({className}) => {
    return (
        <div className={className}>
            <Switch>
                <PrivateRoute path="/" exact>
                    <Profile/>
                </PrivateRoute>
                <PrivateRoute path="/profile" exact>
                    <Profile/>
                </PrivateRoute>
                <PrivateRoute path="/profile/edit">
                    <ProfileEdit/>
                </PrivateRoute>
                <PrivateRoute path="/account" exact>
                    <Account/>
                </PrivateRoute>
                <PrivateRoute path="/account/edit">
                    <AccountEdit/>
                </PrivateRoute>
                <PrivateRoute path="/whatis">
                    <Webinars/>
                </PrivateRoute>
                <PrivateRoute path="/admin-call-settings">
                    <AdminDefaultCallSettings/>
                </PrivateRoute>
                <PrivateRoute path="/admin-virtual-background">
                    <AdminTheme/>
                </PrivateRoute>
                <PrivateRoute path="/admin-colors">
                    <AdminTheme/>
                </PrivateRoute>
                <PrivateRoute path="/recording">
                    <Recordings/>
                </PrivateRoute>
                <PrivateRoute path="/meeting/schedule">
                    <ScheduleMeeting/>
                </PrivateRoute>

                <PrivateRoute path="/meeting">
                    <Meetings/>
                </PrivateRoute>
            </Switch>

        </div>
    )
}

const MainContent = styled(MainContent_)`
    flex-grow: 1;
    background-color: #fffeff;
    padding: 32px 48px;
`

export default MainContent