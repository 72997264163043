import React, { useReducer, createContext } from "react";

const initialState = {
    activePopup: null, // id of active popup/menu
}

export const SettingsContext = createContext(initialState);

const reducer = (state, updates) => {
    return {...state, ...updates}
    // switch(action.type) {
    //     case "UPDATE":
    //         return {...state, ...action.payload};
    //     default:
    //         return state;
    // }
}

export const SettingsProvider = (props) => {
    const thereducer = useReducer(reducer, initialState);
    return <SettingsContext.Provider {...props} value={thereducer} />
}