import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import Button from "../../Elements/Button";


const JoinMeeting_ = ({className}) => {
    return (
        <div className={className}>
            <h1>
                Join a Meeting
            </h1>

            <div className="form-container">
                <div className="form-group confno">
                    <div className="controls">
                        <input id="join-confno" className="form-control input-lg confno" placeholder="Meeting ID or Personal Link Name" />
                    </div>
                </div>

                <Button id="join-meeting" data-tag-name="join button" className='schedule-meeting'  style={{
                        padding: '12px 0',
                        fontSize: 20,
                        marginLeft: 0,
                }}>Join</Button>

            </div>

            
            
        </div>
    )
}

const JoinMeeting = styled(JoinMeeting_)`
    margin: 20px;
    margin-right: 200px;

    h1 {
        font-size: 32px;
        margin: 72px 0 48px;
        text-align: center;
    }
    
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }


    .form-container {
        width:360px;
        margin: 0 auto;
    }
    
    
    .form-group.confno {
        margin-bottom: 16px;
    }

    .form-control {
            display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }


    .input-lg {
            height: 46px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.33;
        border-radius: 6px;
    }

    .form-control {
        color: #232333;
        border-radius: 8px;
        box-shadow: none;
        height: 32px;
        line-height: 32px;
        padding-top: 0;
        padding-bottom: 0;
        border-color: #babacc;
    }
    .input-lg {
        font-size: 24px;
        font-weight: lighter;
        border-width: 2px;
        border-color: #eee;
        height: 52px;
    }

    .form-control.input-lg.confno {
        font-size: 20px;
        height: 56px;
        text-align: center;
    }
    #join-form .form-control, #join-form .btn {
        border-radius: 12px;
    }

    
`

export default JoinMeeting