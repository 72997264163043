import styled from "styled-components";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Route, Link, Redirect } from "react-router-dom";
import steeltoe from 'steeltoe'
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import Breadcrumbs from "../../Elements/Breadcrumbs";
import FormRow from "../../Elements/FormRow";
import Input from "../../Elements/Input";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../Elements/Button";
import { useForm } from "react-hook-form";
import { auth, db } from "../../../config/firebase";
import moment from 'moment';
import { userFields } from "./Profile";


const Alert = styled.div`
    padding: 6px 8px;
    border: 1px solid red;
    border-radius: 6px;
`


const Content_ = ({className}) => {
    // const [startDate, setStartDate] = useState(new Date());
    // const [startDate, setStartDate] = useState(minDate.toDate());
    const { register, handleSubmit, watch, errors } = useForm();
    const [firebaseResult, setFirebaseResult] = useState(null);
    const [profileData, setProfileData] = useState(null);

    useEffect(()=>{

        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                setProfileData(snap.data())
            })
            .catch(err=>{
                console.log('Failed getting profile data:', err)
            })

    },[auth.currentUser.email]);
    
    // const minDate =  moment().second() ||  moment().millisecond() ?  moment().add(1, 'hour').startOf('hour') : null;

    const onSubmit = (data) => {

        // console.log('onSubmit', data);
        
        // const randMeetingId = Math.floor(1000000000 + Math.random() * 9000000000);
        
        let sendData = {
            uid:auth.currentUser.uid,
            ...data
        }

        userFields.forEach(field => {
            const type = field.type;
            if(field.type === 'number'){
                sendData[field.key] = parseInt(sendData[field.key],10);
                if(sendData[field.key]!==sendData[field.key]){//NaN
                    sendData[field.key] = null;
                }
            }
        })

        console.log('onSubmit sendData:', sendData);

        db.collection('users').doc(auth.currentUser.email)
            .set(sendData, {merge: true})
            .then(snap => {
                console.log('Profile saved');
                setFirebaseResult({ type:'success' });

            }).catch( err => {
                console.log('Error saving profile', err)
                setFirebaseResult({ type:'error', message: err.message });
            })
        
    }

    if(!profileData) {
        return null;
    }

    if(firebaseResult&&firebaseResult.type==='success'){
        return <Redirect to="/profile" />
    }




    return (
        <div className={className}>

            <Breadcrumbs path={[
                { name: 'Profile', to: "/profile"},
                { name: 'Edit Profile'}
            ]}/>

            <h3>Edit your Profile</h3>

            <form onSubmit={handleSubmit(onSubmit)}>

                {firebaseResult && <Alert {...firebaseResult}>{firebaseResult.message}</Alert>}


                { userFields.map(userField => {
                    return (
                        <FormRow key={userField.key}>
                            <div>{userField.title}</div>
                            <div>
                            <Input name={userField.key} 
                                defaultValue={profileData[userField.key]}
                                placeholder={userField.title}
                                // inputRef={register}

                                inputRef={register(userField.required && {
                                    validate: value => value ? true : 'Enter '+userField.title
                                })}
                                invalid={!!steeltoe(errors).get(userField.key+'.message')}
                                message={steeltoe(errors).get(userField.key+'.message')}
                                type={userField.type || 'text'}
                            /> 
                            </div>
                        </FormRow>
                    )
                })}

                

                <FormRow style={{
                    marginTop: 24,
                    paddingTop: 24,
                    borderTop: '1px solid #eeeff2'
                }}>
                    <div></div>
                    <div>
                        <Button id="profile-cancel-save" style={{width:"auto", padding: '6px 27px'}} secondary to="/meeting">Cancel</Button>
                        <Button id="profile-save" style={{width:"auto", padding: '6px 27px'}} type="submit">Save</Button>
                        {/* <input style={{width:"auto", padding: '6px 27px'}} type="submit"></input> */}

                    </div>
                </FormRow>
            </form>

            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content