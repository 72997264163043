import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import JoinMeeting from "./JoinMeeting";
import MeetingTemplates from "./MeetingTemplates";
import PersonalMeeting from "./PersonalMeeting";
import CloudRecordings from "./Recordings/CloudRecordings";
import CloudRecordingsCreate from "./Recordings/CloudRecordingsCreate";
import LocalRecordings from "./Recordings/LocalRecordings";

import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";


const Content_ = ({className}) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email)
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })

                
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);

    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);

        // PX
        window.aptrinsic("identify",
            IdentifyUser,
            IdentifyAccount
        );

        // const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // console.log("setting globalContext to version=",vNum);
        // window.aptrinsic('set', 'globalContext', {"version":vNum});
        // console.log("set globalContext to version=",vNum);
    }

    return (
        <div className={className}>


            <Tabs>
                <Tab id="recordings-cloud" to="/recording" exact>Cloud Recodings</Tab>
                {/* <Tab id="recordings-local" to="/recording/local">Local Recordings</Tab> */}
            </Tabs>

            
            <PrivateRoute path="/recording/create">
                <CloudRecordingsCreate/>
            </PrivateRoute>
            
            <PrivateRoute path="/recording" exact>
                <CloudRecordings />
            </PrivateRoute>


            <PrivateRoute path="/recording/local">
                <LocalRecordings/>
            </PrivateRoute>

           

            {/* <div className="right">
                <Link to="/meetings-more">
                    Learn More
                </Link>
            </div> */}
            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content