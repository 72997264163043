// import React from 'react';
import React, { Component } from "react";
import './App.scss';
import GlobalStyle from './style'
import Header from './ui/Header/Header';
import Footer from './ui/Footer';
import TwoColsLayout from './ui/Layouts/TwoColsLayout'
import AuthLayout from './ui/Layouts/AuthLayout'
import { Route, Switch } from 'react-router-dom';




// function App() {

class App extends Component {
  componentDidMount() {
    
    // this.setupPX();

  }

  // setupPX = () => {


  //   const isLocalhost = Boolean(
  //     window.location.hostname === 'localhost' ||
  //       // [::1] is the IPv6 localhost address.
  //       window.location.hostname === '[::1]' ||
  //       // 127.0.0.1/8 is considered localhost for IPv4.
  //       window.location.hostname.match(
  //         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  //       )
  //   );

  //   (function(n,t,a,e){var i="aptrinsic";n[i]=n[i]||function(){
  //     (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;
  //   var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
  //   var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
  // })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","AP-XN9KVUBUM0MX-2");


  // }

  render() {


    return (
      <>
        <GlobalStyle/>
        <div className="App">
          <Header/>
          <Switch>
            <Route path={["/signin", "/signup"]} component={AuthLayout} />
            <Route component={TwoColsLayout} />
          </Switch>
          {/* <Footer/> */}
        </div>
      </>
    );
  }
}

export default App;
